import React, { PureComponent } from 'react'
import Helmet from 'next/head'
import PropTypes from 'prop-types'
import { Constants } from '../../adapters/helpers/Constants';
import ImageFactory from '../../adapters/cloudinary/ImageFactory';
import DOMPurify from 'isomorphic-dompurify';
import { optimizeImage } from '../../adapters/helpers/pagespeedHelper';


class ArticlePageSEO extends PureComponent {
    constructor(props) {
        super(props)
    }

    static propTypes = {
        metadata: PropTypes.object,
        article: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
        pagePath: PropTypes.string.isRequired,
        brandLogoUrl: PropTypes.string.isRequired,
        bannerURL: PropTypes.string.isRequired
    }

    static defaultProps = {
        articleData: {
            imageHeight: '',
            imageWidth: '',
            steps: []
        },
    }

    render() {
        const { metadata, pagePath, article} = this.props
        const BASE_URL = process.env.BASE_URL;    
        return (
            <Helmet>
                <script
                    type={Constants.typeLD}
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(`{
                            "@context": "${Constants.context}",
                            "@type": "${Constants.article}",
                            "author": "${Constants.brand} – P&G",
                            "datePublished": "",
                            "dateModified": "",
                            "mainEntityOfPage":{
                                "@type": "${Constants.WebPage}",
                                "@id":"${BASE_URL}${pagePath}"
                            },
                            "headline": "${metadata?.metaTitle}",
                            "image": {
                                "@type": "${Constants.ImageObject}",
                                "url": "${article?.articleOverview?.fields?.thumbnailImage?.fields?.assetId ? optimizeImage(ImageFactory.buildContentfullImageUrl(article?.articleOverview?.fields?.thumbnailImage?.fields?.asset?.fields?.file?.url)) : ''}"
                            },
                        "publisher": {
                                "@type": "${Constants.organization}",
                                "name": "${Constants.brand}",
                                "url": "${BASE_URL}",
                                "logo": {
                                "@type":  "${Constants.ImageObject}",
                                "url": "${Constants.url || ''}"
                                }
                            },
                            "description": "${metadata?.metaDescription || ''}"
                        }`)
                    }}
                />
            </Helmet>
        )
    }
}

export default ArticlePageSEO
